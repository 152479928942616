import { IAppService, IStateConfig, IFxpAppContext, IServiceEndPoints, IRouteInfo,
   IPartnerBundle, ISharedComponents, IAppServiceWithSharedComponent, ISharedComponentConfig } from '@fxp/fxpservices';
import { appSettings } from './environments/common/appsettingsGenerator';
import { PartnerAppRegistrationService } from '@fxp/fxpservices';

export class SampleApplication_routes implements IAppService, IAppServiceWithSharedComponent {

    getRoutes(fxpContext: IFxpAppContext): IRouteInfo {
        const pageTourRoute: IStateConfig = {
        name: 'pagetour',
        url: '/pagetour',

generatedBundle: '34f2814e89dc-PageTourModule-Bundle',
        data: {
                headerName: 'PageTour Flyout',
                breadcrumbText: 'Flyout Component',
                pageTitle: 'PageTour Flyout title'
             },
        };
        const routeInfo: IRouteInfo = {
            sharedBundles: ['pagetour-Bundle'],
            routes: [pageTourRoute],
            applicationName:'pagetour'
        }
    
        return routeInfo;
    }

    getServiceEndPoints(): Array<IServiceEndPoints> {

        return appSettings().serviceEndPoints;
    }

    getBundles(): IPartnerBundle[] {
        const baseUrl = appSettings().cdnBaseUrl;
        const bundle: IPartnerBundle = {
          name: 'pagetour-Bundle', //TODO: Please use the format {appname}-{bundleName} to ensure the name of the bundle is always unique. 
          files: [
            `${baseUrl}/vendor.bundle.js`,
            `${baseUrl}/styles.bundle.js`,
            `${baseUrl}/main.bundle.js`,
          ],
          sequentialLoading: true,
        };

        return [bundle];
      }

      getSharedComponentsInfo(): ISharedComponents {
        const pageTourSharedComp: ISharedComponentConfig = {

generatedBundle: '34f2814e89dc-PageTourModule-Bundle',
            componentDescription: 'This component renders page tour flyout.',
            componentInputs: [],
            componentOutputs: [],
            componentName: 'pagetour-flyout'
        };

        return {
            appName: 'pagetour',
            components: [pageTourSharedComp],
            disableSharing: false,
            sharedBundles: []
        };
    }
  public getGeneratedBundles() {
    var baseUrl = '';
    var currentScriptUrl = document.currentScript['src'];
    if (currentScriptUrl.indexOf('exclude.inline.bundle') > 0) {
      currentScriptUrl = Error().stack.match(/(https?:\/\/[^ ]*)/g);
      baseUrl = currentScriptUrl
        .filter((item) => item.indexOf('localhost:5000') === -1)[0]
        .toLowerCase();
      baseUrl = baseUrl.substring(0, baseUrl.lastIndexOf('/'));
    } else {
      baseUrl = currentScriptUrl.substring(0, currentScriptUrl.lastIndexOf('/'));
    }
    if (baseUrl.endsWith("/")){
        baseUrl = baseUrl.substring(0, baseUrl.lastIndexOf('/'));
    }
    return [
            {
                name: '34f2814e89dc-PageTourModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/PageTourModule.vendor.bundle.js',
                    baseUrl + '/PageTourModule.bundle.js'
                ],
                sequentialLoading: true           
            },

    ]
}
}

PartnerAppRegistrationService.registerLazyLoadedApp(SampleApplication_routes, 'pagetour');
