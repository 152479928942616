import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const sit: IPartnerAppSettings = {
  cdnBaseUrl: 'https://fxpsit.azureedge.net/pagetour',
  serviceEndPoints: [
    {
      clientId: '97c43e67-181d-44a9-9124-aec04ee9a844',
      serviceEndPoint: 'https://pagetourapisit.azurewebsites.net/',
    },
  ],
  tenantId: '624c65a2-4295-4697-97b8-14241015f123',
  apiUrl: 'https://pagetourapisit.azurewebsites.net/api/v1/',
  surveyId: 'c7b119c2-6b85-4434-9e3b-7cbc19c8cc6f'
};
