import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const uat: IPartnerAppSettings = {
  cdnBaseUrl: 'https://fxpsit.azureedge.net/pagetour',
  serviceEndPoints: [
    {
      clientId: 'cbd1fcd7-3769-4123-8ac9-cb51188ec3ba',
      serviceEndPoint: 'https://pagetourserviceuatwest.azurewebsites.net',
    },
  ],
  tenantId: '5e27714c-0268-4a6d-8552-82b62164a113',
  apiUrl: 'https://pagetourserviceuatwest.azurewebsites.net/api/v1/',
  surveyId: 'c7b119c2-6b85-4434-9e3b-7cbc19c8cc6f'
};
